<template>
  <div class="flex h-screen gap-6">
    <div class="w-3/5 shadow-2xl">
      <img
        class="hidden object-cover w-full h-screen md:block"
        src="@/assets/images/authImage.jpg"
      />
    </div>
    <div class="bg-lightGrey sm:w-8/12 md:w-1/2 lg:w-500 rounded-xl">
      <base-dialog
        :show="!!error"
        :errorIcon="!!error"
        title="An error occurred"
        @close="handleError"
      >
        <p>{{ error }}</p>
      </base-dialog>
      <base-dialog :show="isLoading" title="Please Wait...">
        <heart-rate fast="true"></heart-rate>
      </base-dialog>

      <div class="text-4xl text-darkGrey px-12 pb-4 pt-7 font-bold">
        Forgot Password
      </div>
      <div class="font-normal text-grey px-12 pt-3">
        Enter email and get reset password link
      </div>
      <div class="pt-4 px-6 pb-0 my-5 mx-5">
        <form @submit.prevent="submitResetForm" class="form-login">
          <input
            id="resetPassword_email"
            name="email"
            type="email"
            class="w-full rounded-sm py-5 pr-0 pl-2 focus:outline-none"
            placeholder="Email"
            required
            v-model.trim="email"
            :disabled="!!error"
          />
          <base-button :disabled="!!error">SUBMIT</base-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      isLoading: false,
      error: null,
    };
  },
  methods: {
    async submitResetForm() {
      const actionPayload = {
        email: this.email,
      };
      this.isLoading = true;
      try {
        await this.$store.dispatch("auth/forgotPassword", actionPayload);
        this.$router.replace("/");
      } catch (err) {
        this.error = err.message;
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.form-login input[type="email"]:focus,
.form-login input[type="email"]:hover,
.form-login input[type="password"]:focus,
.form-login input[type="password"]:hover {
  background-color: #ffffff;
  border-left: solid 3px #00a499;
  filter: drop-shadow(2px 5px 5px rgb(219, 219, 219));
}
</style>
